import React from 'react';

export default function Hero() {
  return (
    <section id="hero">
      <div className="container">
        <h1>Welcome to Ybor City Valet</h1>
        <h3>Through Evolution Parking</h3>
      </div>
    </section>
  );
}

  



